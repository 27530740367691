<template>
  <v-tooltip 
    bottom
    content-class="modern-tooltip"
    :max-width="300"
    transition="fade-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        color="primary"
        v-bind="attrs"
        v-on="on"
        small
        class="tooltip-icon ml-2"
      >
        mdi-information-outline
      </v-icon>
    </template>
    <span class="tooltip-content"><slot></slot></span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'ToolTip',
}
</script>

<style scoped>
.tooltip-icon {
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.2s ease;
}

.tooltip-icon:hover {
  opacity: 1;
  transform: scale(1.1);
}
</style>

<style>
.modern-tooltip {
  background-color: rgba(33, 33, 33, 0.95) !important;
  border-radius: 8px !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  padding: 8px 12px !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2) !important;
}
</style>
